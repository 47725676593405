import clsx from "clsx";
import React, { memo, useEffect, useState } from "react";
import g from "../../../../assets/styles/Main.module.scss";
import s from "./Confirm.module.scss";
import { useSelector } from "react-redux";
import { getGtinScannerValidate } from "redux/Line/lineSelectors";

export const ModalGtinCheck = memo(({
  onAgree,
  onDisagree,
  onClose,
}) => {
  const [partNum, setPartNum] = useState("");
  const [boxNum, setBoxNum] = useState(null);
  const gtinScannerValidateStatus = useSelector(getGtinScannerValidate)

  useEffect(() => {

    setPartNum(localStorage.getItem('partNumber'))
    setBoxNum(localStorage.getItem('boxNumber'))
  }, []);

  const handleAgree = () => {
    localStorage.setItem('partNumber', partNum);
    localStorage.setItem('boxNumber', boxNum);
    onAgree({ partNum, boxNum: Number(boxNum) });
  }

  useEffect(() => {
    if (gtinScannerValidateStatus === 'Valid') {
      handleAgree()
    }
  }, [gtinScannerValidateStatus])

  return (
    <>
      <div
        className={
          s.confirm
        }
        style={{ height: "auto", paddingBottom: "1.5rem" }}
      >
        Отсканируйте штрих-код продукта с помощью ТСД
        <div className={s.controls}>
          <button
            type="button"
            onClick={onDisagree}
            className={clsx(s.btn, s.btnDisagree)}
          >
            {'Закрыть'}
          </button>
        </div>
      </div >
      <div className={g.overlay} onClick={onClose} />
    </>
  );
});
