export const getLineSettings = (state) => state.settings?.line;
export const getConsignmentId = (state) => state.settings?.line?.consignmentId;
export const getIsConsignment = (state) =>
  state.settings?.line?.consignmentStatus;
export const getIsAddMarking = (state) =>
  state.settings?.line?.BindingMarkingCodesToBatchWhenPrinting;
export const getLineName = (state) => state.settings?.line?.name;
export const getLineId = (state) => state.settings?.line?.id;
export const getSelectedProductId = (state) =>
  state.settings.line?.production?.selectedProductId;
export const getLineProducts = (state) =>
  state.settings.line?.production?.products;
export const getTestMode = (state) => state.settings.line?.testMode;
export const getSelectedLineData = (state) => state.settings.line?.checkProductGtinBeforeStart;
