import SettingsAPI from '../../api/SettingsAPI'
import { FULL_ACCESS } from '../../constants'
import { openSettings, setErrorText } from '../App/appActionCreators'
import { changeTypeLine, setAllSettings, setLoading } from './BaseSettings/baseSettingsActionCreators'
import { getLineInfo, setProductInfo } from '../Line/lineActionCreators'


export const saveSettings = () => async (dispatch, getState) => {
    const settings = getState()?.settings
    const lineId = getState()?.settings?.line?.id
    const productId = getState().settings?.line?.production?.selectedProductId
    const consignmentId = getState().settings?.line?.consignmentId
    const type = getState().settings?.baseSettings?.typeLine;

    await dispatch(setLoading(true))

    let savingSettingsRes, changingProductRes

    if ([FULL_ACCESS].includes(getState()?.auth?.accessLevel)) {
        const { line, printing, scanning, aggregation, plc, weighingScale } = settings;
        savingSettingsRes = await SettingsAPI.save({ line: { ...line, type }, printing, scanning, aggregation, plc, weighingScale })
    }

    changingProductRes = await SettingsAPI.changeSelectedProduct(lineId, productId, consignmentId)

    if (savingSettingsRes?.statusCode === 200 || (changingProductRes && changingProductRes?.statusCode === 200)) {
        await dispatch(getLineInfo())
        // await dispatch(setProductInfo(lineId))
        await dispatch(openSettings(false))
    } else {
        await dispatch(setErrorText(savingSettingsRes?.message))
        await dispatch(setErrorText(changingProductRes?.message))
    }
    await dispatch(setLoading(false))
}

export const resetSettings = () => async (dispatch) => {
    let settings = await SettingsAPI.getSelectedLine()

    if (!settings.statusCode) {
        await dispatch(setAllSettings(settings))
        await dispatch(changeTypeLine(settings?.line?.type));
        await dispatch(openSettings(false))
    } else {
        await dispatch(setErrorText(settings?.message))
    }
}
