import {
  CHANGE_SELECTED_CONSIGNMENT,
  CHANGE_SELECTED_PRODUCT,
  SET_ALL_LINE_SETTINGS,
  SET_IS_CONSIGNMENT,
  SET_IS_MARKING,
  SET_LINE_SETTINGS,
  UPDATE_PRODUCTS,
  SET_TEST_MODE,
  SET_SELECTED_LINE_DATA
} from "./lineSettingsActions";

export const setUpdatedProducts = (productsArr) => ({
  type: UPDATE_PRODUCTS,
  payload: productsArr,
});
export const setSelectedLineData = (data) => ({
  type: SET_SELECTED_LINE_DATA,
  payload: data,
});
export const setLineSettings = (value, key) => ({
  type: SET_LINE_SETTINGS,
  value,
  key,
});
export const setAllLineSettings = (settings) => ({
  type: SET_ALL_LINE_SETTINGS,
  settings,
});
export const changeSelectedProduct = (productID) => ({
  type: CHANGE_SELECTED_PRODUCT,
  productID,
});
export const changeSelectedConsignment = (consignmentID) => ({
  type: CHANGE_SELECTED_CONSIGNMENT,
  consignmentID,
});
export const setIsConsignmentAC = (isConsignment) => ({
  type: SET_IS_CONSIGNMENT,
  isConsignment,
});
export const setIsAddMarkingAC = (isAddMarking) => ({
  type: SET_IS_MARKING,
  isAddMarking,
});

export const setIsConsignment = (isConsignment) => async (dispatch) => {
  await dispatch(setIsConsignmentAC(isConsignment));

  if (!isConsignment) {
    dispatch(changeSelectedConsignment(null));
  }
};

export const toggleTestMode = (isTestMode) => ({
  type: SET_TEST_MODE,
  isTestMode,
});
