export const RUN_LINE = "RUN_LINE";
export const STOP_LINE = "STOP_LINE";
export const PAUSE_LINE = "PAUSE_LINE";
export const SET_LINE_STATS = "SET_LINE_STATS";
export const SET_LINE_RUNNED = "SET_LINE_RUNNED";
export const SET_PRODUCTION_STATS = "SET_PRODUCTION_STATS";
export const SET_LOADING_CONTROL = "SET_LOADING_CONTROL";
export const SET_LINE_STOP = "SET_LINE_STOP";
export const ACTIVATE_STOP_SIGNAL = "ACTIVATE_STOP_SIGNAL";
export const ACTIVATE_ERROR_SIGNAL = "ACTIVATE_ERROR_SIGNAL";
export const SET_SELECTED_CONSIGNMENT = "SET_SELECTED_CONSIGNMENT";
export const SET_EMULATION_MODE = "SET_EMULATION_MODE";
export const RESET_LINE = "RESET_LINE";
export const UPDATE_PRODUCTS_TABLE = "UPDATE_PRODUCTS_TABLE";
export const UPDATE_BOXES_TABLE = "UPDATE_BOXES_TABLE";
export const UPDATE_PALLETS_TABLE = "UPDATE_PALLETS_TABLE";
export const SET_LINE_PRODUCTION_TIME = "SET_LINE_PRODUCTION_TIME";
export const SET_LINE_ITEM_DROPOUT = "SET_LINE_ITEM_DROPOUT";
export const SET_LINE_ITEM_SUCCESS = "SET_LINE_ITEM_SUCCESS";
export const GET_SCANNED_ITEM_PRODUCTLEVEL = "GET_SCANNED_ITEM_PRODUCTLEVEL";
export const GET_PRODUCT_INFO = "GET_PRODUCT_INFO";
export const SET_PRELOADED_STATS = "SET_PRELOADED_STATS";
export const SET_BOX_FORMED = "SET_BOX_FORMED";
export const UPDATE_PRODUCTS_TABLE_FOR_DROPOUT =
  "UPDATE_PRODUCTS_TABLE_FOR_DROPOUT";
export const SET_IS_LINE_LOADING = "SET_IS_LINE_LOADING";
export const SET_GTIN_SCANNER_VALIDATE = 'SET_GTIN_SCANNER_VALIDATE'
