import React, { useEffect, useLayoutEffect, useState } from "react";
import Loader from "components/Common/Loader/Loader";
import { SelectingAlert } from "components/Common/Alerts/SelectingAlert/SelectingAlert";
import { Alert } from "components/Common/Alerts/Alert/Alert";
import { getSelectedLineForModal } from "redux/Line/lineActionCreators";
import { useDispatch, useSelector } from "react-redux";
import { getAllLines } from "redux/App/appSelectors";
import { getIsLineLoading } from "redux/Line/lineSelectors";
import { useDisablePreloader } from "hooks";
import Confirm from "components/Common/Alerts/Confirm/Confirm";

const LineSelectingModal = ({ lines, onSelectingLine, lineId, closeAll }) => {
  const dispatch = useDispatch();
  const allLines = useSelector(getAllLines);
  const [linesToShow, setLinesToShow] = useState(lines);
  const isLoading = useSelector(getIsLineLoading);

  useLayoutEffect(() => {
    const getLines = async () => {
      dispatch(getSelectedLineForModal(dispatch));
    };
    if (!lines.length) {
      getLines();
    }
  }, []);

  useEffect(() => {
    if (allLines?.length) {
      setLinesToShow(allLines);
    }
  }, [allLines]);

  return (
    <>
      {isLoading ? (
        <Confirm text={"Выбор линии"} isLoading />
      ) : (
        <>
          {linesToShow?.length ? (
            <SelectingAlert
              text="Выберите линию"
              items={linesToShow}
              onSelect={onSelectingLine}
              selectedItemId={lineId}
              onClose={closeAll}
            />
          ) : (
            <Alert
              text="Для данного пользователя нет доступных линий. Обратитесь к администратору."
              closeText="Понятно"
              onClose={closeAll}
            />
          )}
        </>
      )}
    </>
  );
};

export default LineSelectingModal;
