export const SET_LINE_SETTINGS = "SET_LINE_SETTINGS";
export const SET_ALL_LINE_SETTINGS = "SET_ALL_LINE_SETTINGS";
export const CHANGE_SELECTED_PRODUCT = "CHANGE_SELECTED_PRODUCT";
export const CHANGE_SELECTED_CONSIGNMENT = "CHANGE_SELECTED_CONSIGNMENT";
export const SET_EMULATION_MODE = "SET_EMULATION_MODE";
export const SET_IS_CONSIGNMENT = "SET_IS_CONSIGNMENT";
export const SET_IS_MARKING = "SET_IS_MARKING";
export const UPDATE_PRODUCTS = "UPDATE_PRODUCTS";
export const SET_TEST_MODE = "SET_TEST_MODE";
export const SET_SELECTED_LINE_DATA = "SET_SELECTED_LINE_DATA";
