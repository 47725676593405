import {
  CHANGE_SELECTED_CONSIGNMENT,
  CHANGE_SELECTED_PRODUCT,
  SET_ALL_LINE_SETTINGS,
  SET_IS_CONSIGNMENT,
  SET_IS_MARKING,
  SET_LINE_SETTINGS,
  UPDATE_PRODUCTS,
  SET_TEST_MODE,
  SET_SELECTED_LINE_DATA
} from "./lineSettingsActions";

const initialState = { line: null };

export const lineSettingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ALL_LINE_SETTINGS:
      return {
        ...action.settings,
      };
    case SET_LINE_SETTINGS:
      return {
        ...state,
        [action.key]: !action.value ? 0 : action.value,
      };
    case CHANGE_SELECTED_PRODUCT:
      return {
        ...state,
        production: {
          ...state.production,
          selectedProductId: action.productID
            ? +action.productID
            : action.productID,
        },
      };
    case CHANGE_SELECTED_CONSIGNMENT:
      return {
        ...state,
        consignmentId: action.consignmentID
          ? +action.consignmentID
          : action.consignmentID,
      };
    case SET_IS_CONSIGNMENT:
      return {
        ...state,
        consignmentStatus: action.isConsignment,
      };

    case SET_IS_MARKING:
      return {
        ...state,
        bindingMarkingCodesToBatchWhenPrinting: action.isAddMarking,
      };
    case UPDATE_PRODUCTS:
      return {
        ...state,
        production: { ...state.production, products: action.payload },
      };
    case SET_TEST_MODE:
      console.log("action from set test mode", action);
      return { ...state, testMode: action.isTestMode };
    case SET_SELECTED_LINE_DATA:
      console.log("action from set selected line", action);

      return {
        ...state,
        checkProductGtinBeforeStart: action.payload
      }
    default:
      return state;
  }
};
